<template>
	<v-app id="app">
		<!-- NAVDRAWER  -->
		<v-navigation-drawer
			app
			v-if="sessionActive"
			v-model="drawer"
			:mini-variant="miniVariant"
			:expand-on-hover="miniVariant"
		>
			<v-toolbar flat dense>
				<v-toolbar-title>{{ tenantName }}</v-toolbar-title>
			</v-toolbar>
			<smart-nav-bar :navItems="navItems" />
		</v-navigation-drawer>

		<!-- APP BAR -->
		<v-app-bar app v-if="sessionActive" flat class="white" short>
			<v-app-bar-nav-icon
				v-if="$vuetify.breakpoint.mobile"
				@click="toggleDrawer"
			></v-app-bar-nav-icon>
			<v-btn v-else icon>
				<v-icon @click.stop="toggleVariant">mdi-menu</v-icon></v-btn
			>
			<v-spacer />
			<v-btn icon plain :to="{ name: 'Logout' }">
				<v-icon>mdi-logout</v-icon>
			</v-btn>
		</v-app-bar>

		<!-- MAIN -->
		<v-main v-resize="onResize" v-scroll="onScroll" class="grey lighten-4">
			<transition name="fade" mode="out-in">
				<keep-alive>
					<router-view> </router-view>
				</keep-alive>
			</transition>
		</v-main>

		<!-- SCROLL TOP -->
		<v-btn
			v-if="sessionActive && this.scroller"
			style="position: fixed; bottom: 0; right: 0"
			x-large
			fab
			color="info"
			icon
			v-on:click.stop="$vuetify.goTo(0)"
		>
			<v-icon>mdi-arrow-up-thin-circle-outline</v-icon>
		</v-btn>

		<!-- BOTTOM SHEET -->
		<v-bottom-sheet
			v-model="bottomSheet.isVisible"
			hide-overlay
			scrollable
			width="auto"
		>
			<v-sheet v-if="bottomSheet.success"
				><v-btn icon large @click="bottomSheet.isVisible = false"
					><v-icon color="success">mdi-thumb-up</v-icon></v-btn
				></v-sheet
			>
			<v-card v-else class="mx-auto" max-height="150px" dark>
				<v-card-title>
					<v-spacer />
					<v-btn right icon @click="bottomSheet.isVisible = false"
						><v-icon>mdi-close-circle-outline</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="text-center">
					{{ bottomSheet.message }}
				</v-card-text>
			</v-card>
		</v-bottom-sheet>
	</v-app>
</template>

<script>
	import lodash from "lodash";
	import { mapGetters } from "vuex";
	import SmartNavBar from "./components/SmartNavBar.vue";

	export default {
		name: "App",
		components: { SmartNavBar },
		data: () => ({
			drawer: null,
			miniVariant: false,
			scroller: false,
			tenantName: process.env.VUE_APP_TENANT_NAME,
			appName: process.env.VUE_APP_APP_NAME,
			navItems: [
				{
					id: 0,
					text: "Home",
					routeName: "Home",
					icon: "mdi-home-outline",
				},
				{
					id: 1,
					text: "Tickets",
					routeName: "Receipts",
					icon: "mdi-alpha-t-circle-outline",
				},
			],
		}),
		computed: {
			...mapGetters({
				sessionActive: "session/sessionActive",
				bottomSheet: "session/bottomSheet",
			}),
		},
		methods: {
			onResize() {
				let fx = lodash.debounce(this.setContentHeight, 1000);
				fx();
			},
			onScroll() {
				this.scroller = window.pageYOffset > 256;
			},
			setContentHeight() {
				this.$store.dispatch("session/updateUI", {
					contentHeight:
						window.innerHeight -
						this.$vuetify.application.top -
						this.$vuetify.application.footer,
				});
			},
			toggleDrawer() {
				if (this.$vuetify.breakpoint.mobile) {
					if (!this.drawer) {
						this.drawer = true;
						this.miniVariant = false;
					} else {
						this.drawer = false;
					}
				}
			},
			toggleVariant() {
				if (this.$vuetify.breakpoint.mobile) {
					this.drawer = false;
				} else {
					this.miniVariant = !this.miniVariant;
				}
			},
		},
	};
</script>

<style>
	/*** SCROLLBAR ***/
	/* width */
	::-webkit-scrollbar {
		width: 8px;
	}
	/* Track */
	::-webkit-scrollbar-track {
		background: #eceff1; /* blue-grey lighten-5  */
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #b0bec5; /* blue-blue-grey lighten-5  */
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #78909c; /* blue-grey lighten-1 */
	}

	/*** TRANSITIONS ***/
	.fade-enter {
		opacity: 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease-out;
	}

	.fade-leave-to {
		opacity: 0;
	}

	.slide-fade-enter {
		transform: translateX(10px);
		opacity: 0;
	}

	.slide-fade-enter-active,
	.slide-fade-leave-active {
		transition: all 0.25s ease;
	}

	.slide-fade-leave-to {
		transform: translateX(-10px);
		opacity: 0;
	}
	.kmp {
		position: fixed;
		left: 50%;
		transform: translate(-50%, 0%);
	}
</style>
