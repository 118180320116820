<style scoped>
	.bg {
		/*background-image: url("../assets/logo.svg");*/
		height: 50%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		position: relative;
	}
	.cntnr {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
</style>
<template>
	<div class="bg primary lighten-1" v-if="!sessionActive">
		<v-card class="cntnr" width="400" height="500" max-height="100vh" max-width="100vw">
			<v-card-title class="text-center d-flex flex-column">
				<v-img class="my-6" src="@/assets/logo.png" height="40px" contain position="center center" />
				<span>{{ appName }}</span>
			</v-card-title>
			<v-card-subtitle class="text-center">
				Aanmelden met je Continuans account</v-card-subtitle>
			<v-card-text class="my-4">
				<v-form @keyup.native.enter="submitLogin">
					<v-container>
						<v-row>
							<v-col cols="3">
								<v-text-field v-model="tenantId" type="number" label="Dossiernr" persistent-placeholder
									hide-details autofocus />
							</v-col>
							<v-col cols="9">
								<v-text-field v-model="userName" type="text" label="Gebruiker" persistent-placeholder
									hide-details autofocus />
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field v-model="userPwd" :type="showPassword ? 'text' : 'password'" :append-icon="
																		showPassword ? 'mdi-eye' : 'mdi-eye-off'
																	" @click:append="showPassword = !showPassword" label="Wachtwoord" persistent-placeholder
									hide-details /></v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn class="px-6" :loading="loading" color="primary" @click.stop="submitLogin()">
					Aanmelden
				</v-btn>
				<v-spacer />
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import caas from "@/services/caas";
	import moment from "moment";
	export default {
		name: "Login",
		data: () => ({
			userName: "",
			userPwd: "",
			showPassword: false,
			loading: false,
			appName: process.env.VUE_APP_APP_NAME,
			tenantName: process.env.VUE_APP_TENANT_NAME,
			tenantId: 0,
		}),
		computed: {
			...mapGetters({
				sessionActive: "session/sessionActive",
			}),
		},
		methods: {
			submitLogin() {
				const that = this;
				this.loading = true;
				window.setTimeout(function () {
					that.login();
				}, 750);
			},
			login() {
				this.loading = true;
				const credentials = {
					tenantId: Number(this.tenantId),
					userName: this.userName,
					userPwd: this.userPwd,
				};
				caas.rpc("appUserLogin", credentials)
					.then((response) => {
						if (response.data) {
							const id = response.data.webSessionId;
							this.$store.commit("session/setWebSessionId", id);
							localStorage.setItem("webSessionId", id);
							localStorage.setItem(
								"webSessionDayOfYear",
								moment().dayOfYear()
							);
							this.$router.push({ name: "Home" });
						} else {
							if (response.data.error) {
								this.$store.dispatch(
									"session/showBottomMessage",
									response.data.error
								);
							}
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
		mounted() {},
	};
</script>

