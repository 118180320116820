<template>
	<v-container fluid class="pa-0">
		<v-toolbar flat dense :style="stickyTop">
			<v-toolbar-title> Dashboard </v-toolbar-title>
			<v-spacer />
			<span
					style="max-width: 500px"
					class="my-auto text-caption font-weight-light text-right"
					>{{ lastUpdated }}</span>
			<v-icon class="ml-3" @click.stop="refreshDashboard">mdi-refresh</v-icon>
		</v-toolbar>
		<v-container>
			<v-row>
				<!-- KASTICKETS -->
				<v-col cols="12" md="6">
					<!-- SUMMARY -->
					<v-card flat outlined>
						<v-card-title> Weekomzet </v-card-title>
						<v-card-text class="d-inline-flex flex-column flex-md-row">
							<v-text-field class="text-h6 ma-3" :value="totalSalesValue" label="Omzet" outlined readonly flat
								hide-details />
							<v-text-field class="text-h6 ma-3" :value="totalSalesCount" label="#Kastickets" outlined
								readonly hide-details />
						</v-card-text>
					</v-card>
					<v-card flat outlined class="mt-4">
						<v-card-title> Omzet per weekdag </v-card-title>
						<v-card-subtitle>
							<v-sparkline type="trend" height="50" :labels="labels2" :value="values2" auto-line-width
								auto-draw color="primary" />
						</v-card-subtitle>
						<v-card-text>
							<v-data-table :items="salesByDay" :headers="headers2" :loading="loading" hide-default-footer
								disable-pagination disable-sort dense />
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="6">
					<v-card flat outlined>
						<v-card-title> Dagomzet </v-card-title>
						<v-card-text>
							<v-data-table :items="cashReceipts" :headers="headers" :loading="loading" hide-default-footer
								disable-pagination disable-sort dense height="600" fixed-header />
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>
<script>
	import caas from "@/services/caas";
	export default {
		name: "Home",
		mounted() {
			this.refreshDashboard();
		},
		data() {
			return {
				loading: false,
				mounted: false,
				cashReceipts: [],
				salesByDay: [],
				totalSalesValue: 0,
				totalSalesCount: 0,
				lastUpdated: "",
			};
		},
		computed: {
			stickyTop() {
				return {
					position: "sticky",
					top: this.$vuetify.application.top + "px",
					zIndex: 1,
				};
			},
			headers() {
				return [
					{ text: "Kasticket", value: "kastnr" },
					{ text: "Tijdstip", value: "uurstring" },
					{ text: "Totaal", value: "totaal", align: "end" },
					{ text: "Factuurnr", value: "faktuurnr" },
					{ text: "Klantnr", value: "klnr" },
				];
			},
			labels2() {
				let labels = this.salesByDay.map((a) => a.salesDay);
				return labels;
			},
			values2() {
				let values = this.salesByDay.map((a) => a.salesValue);
				return values;
			},
			headers2() {
				return [
					{ text: "", value: "salesDay" },
					{
						text: "Omzet",
						value: "salesValue",
						align: "right",
					},
					{
						text: "#Tickets",
						value: "salesCount",
						align: "right",
					},
					{
						text: "Omzet/ticket",
						value: "avgSalesValue",
						align: "right",
					},
				];
			},
		},
		methods: {
			getCashReceipts() {
				const sdoName = "order/objects/dKasth.r";
				let params = {
					action: "read",
					rowIdent: "",
					nbrOfRows: 999,
					setOpenQuery:
						"for each kasth where kasth.datum = today no-lock indexed-reposition ",
					setQuerySort: "by kastnr ",
				};

				this.loading = true;
				return caas
					.sdo(sdoName, params)
					.then((response) => {
						if (response.data) {
							this.cashReceipts =
								response.data.data.RowObject;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			getPOSDashboard() {
				this.loading = true;
				return caas
					.rpc("getPOSDashboard", {})
					.then((response) => {
						if (response.data) {
							this.salesByDay = response.data.data.salesByDay;
							this.totalSalesValue =
								response.data.totalSalesValue;
							this.totalSalesCount =
								response.data.totalSalesCount;
						}
					})
					.finally(() => (this.loading = false));
			},
			refreshDashboard() {
				this.getCashReceipts().then((result) => {
					this.getPOSDashboard().then(() => {
						this.lastUpdated = new Date().toLocaleString("nl-BE");
						this.mounted = true;
					});
				});
			},
		},
	};
</script>
