import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Login from "@/views/Login";
import Home from "@/views/Home";
import goTo from "vuetify/lib/services/goto";
import moment from "moment";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { requiresAuth: true },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { requiresAuth: false },
    },
    {
        path: "/logout",
        name: "Logout",
        component: lazyLoad("Logout"),
        meta: { requiresAuth: true },
    },
    {
        path: "/receipts",
        name: "Receipts",
        component: lazyLoad("Receipts"),
        meta: { requiresAuth: true },
    },
];

function lazyLoad(view) {
    return () => {
        return import(`@/views/${view}.vue`);
    };
}

const scrollBehavior = function (to, from, savedPosition) {
    let scrollTo = 0;
    if (to.hash) {
        scrollTo = to.hash;
    } else if (savedPosition) {
        scrollTo = savedPosition.y;
    }
    return setTimeout(function () {
        goTo(scrollTo);
    }, 1000);
};

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    scrollBehavior,
});

// N A V I G A T I O N   G U A R D
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // auth required
        if (!store.getters["session/sessionActive"]) {
            // there is no active session
            let webSessionId = Number(localStorage.getItem("webSessionId"));
            let dayOfYear = localStorage.getItem("webSessionDayOfYear");
            if (webSessionId && dayOfYear == moment().dayOfYear()) {
                // retreive session from localstorage
                store.commit("session/setWebSessionId", webSessionId);
                next();
            } else {
                next({ name: "Login" });
            }
        } else {
            // there is an active session
            next();
        }
    } else {
        // no auth required
        next();
    }
});

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title =
            to.meta.title ||
            process.env.VUE_APP_TENANT_NAME +
                " " +
                process.env.VUE_APP_APP_NAME;
    });
});

export default router;
