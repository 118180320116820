import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSparkline } from 'vuetify/lib/components/VSparkline';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VToolbar,{style:(_vm.stickyTop),attrs:{"flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v(" Dashboard ")]),_c(VSpacer),_c('span',{staticClass:"my-auto text-caption font-weight-light text-right",staticStyle:{"max-width":"500px"}},[_vm._v(_vm._s(_vm.lastUpdated))]),_c(VIcon,{staticClass:"ml-3",on:{"click":function($event){$event.stopPropagation();return _vm.refreshDashboard.apply(null, arguments)}}},[_vm._v("mdi-refresh")])],1),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCard,{attrs:{"flat":"","outlined":""}},[_c(VCardTitle,[_vm._v(" Weekomzet ")]),_c(VCardText,{staticClass:"d-inline-flex flex-column flex-md-row"},[_c(VTextField,{staticClass:"text-h6 ma-3",attrs:{"value":_vm.totalSalesValue,"label":"Omzet","outlined":"","readonly":"","flat":"","hide-details":""}}),_c(VTextField,{staticClass:"text-h6 ma-3",attrs:{"value":_vm.totalSalesCount,"label":"#Kastickets","outlined":"","readonly":"","hide-details":""}})],1)],1),_c(VCard,{staticClass:"mt-4",attrs:{"flat":"","outlined":""}},[_c(VCardTitle,[_vm._v(" Omzet per weekdag ")]),_c(VCardSubtitle,[_c(VSparkline,{attrs:{"type":"trend","height":"50","labels":_vm.labels2,"value":_vm.values2,"auto-line-width":"","auto-draw":"","color":"primary"}})],1),_c(VCardText,[_c(VDataTable,{attrs:{"items":_vm.salesByDay,"headers":_vm.headers2,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":"","dense":""}})],1)],1)],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCard,{attrs:{"flat":"","outlined":""}},[_c(VCardTitle,[_vm._v(" Dagomzet ")]),_c(VCardText,[_c(VDataTable,{attrs:{"items":_vm.cashReceipts,"headers":_vm.headers,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":"","dense":"","height":"600","fixed-header":""}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }