import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{attrs:{"dense":"","flat":""}},_vm._l((_vm.navItems),function(navItem){return _c('div',{key:navItem.id},[(!navItem.children)?_c(VListItem,{attrs:{"router":"","to":{ name: navItem.routeName },"exact":"","active-class":"font-weight-medium  primary--text"}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(navItem.icon)}})],1),_c(VListItemTitle,{domProps:{"textContent":_vm._s(navItem.text)}})],1):_c(VListGroup,{attrs:{"prepend-icon":navItem.icon,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,{domProps:{"textContent":_vm._s(navItem.text)}})]},proxy:true}],null,true)},_vm._l((navItem.children),function(_navItem){return _c(VListItem,{key:_navItem.id,attrs:{"router":"","to":{ name: _navItem.routeName },"active-class":"font-weight-medium primary--text"}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_navItem.label)}})],1)}),1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }