import caas from "@/services/caas";

// STATE
const state = {
    webSessionId: 0,
    ui: {
        contentHeight: null,
    },
    bottomSheet: {
        message: "",
        isVisible: false,
        color: "info",
        success: null,
    },
};
// ACTIONS
const actions = {
    updateUI: (context, payload) => {
        context.commit("setContentHeight", payload.contentHeight);
    },
    showBottomMessage: (context, message) => {
        var timeout = 15000;
        context.commit("setBottomMessage", message);
        context.commit("setBottomSuccess", null);
        context.commit("showBottomMessage", true);
        window.setTimeout(function () {
            context.commit("showBottomMessage", false);
        }, timeout);
    },
    showSuccess: (context) => {
        var timeout = 3000;
        context.commit("setBottomMessage", "");
        context.commit("setBottomSuccess", true);
        context.commit("showBottomMessage", true);
        window.setTimeout(function () {
            context.commit("showBottomMessage", false);
        }, timeout);
    },
};
// MUTATIONS
const mutations = {
    setBottomMessage: (state, pMessage) => {
        state.bottomSheet.message = pMessage;
    },
    setBottomSuccess: (state, pSuccess) => {
        state.bottomSheet.success = pSuccess;
    },
    showBottomMessage: (state, pShow) => {
        state.bottomSheet.isVisible = pShow;
    },
    setWebSessionId: (state, pWebSessionId) => {
        state.webSessionId = pWebSessionId;
    },
    setContentHeight: (state, pContentHeight) => {
        state.ui.contentHeight = pContentHeight;
    },
};
// GETTERS
const getters = {
    bottomSheet: (state) => {
        return state.bottomSheet;
    },
    contentHeight: (state) => {
        return state.ui.contentHeight;
    },
    sessionActive: (state) => {
        return state.webSessionId > 0;
    },
    webSessionId: (state) => {
        return state.webSessionId;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
